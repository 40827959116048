import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ImageHandler from './imageHandler'

const Landing = ({ data, parametres }) => {
  var logo = data.file
  return (
    <section className="landing section">
      <div className="container">
        <div className="columns is-mobile is-centered is-vcentered">
          <div className="column is-narrow-tablet">
            <div className="main-content">
              <div className="logo-maintenance">
                <ImageHandler image={logo}/>
              </div>
              <div className="content-maintenance">
                <h1 className="title">Bienvenue sur « cmanaturopathe.com »</h1>
                <h3 className="subtitle">Le site internet de Cynthia Medico, naturopathe à Toulouse.</h3>
                <p>Formée en 2 ans à l’école <a href="https://www.euronature.fr" target="blank" rel="noopener noreferrer">Euronature</a> Toulouse.<br/>
                Certifiée Fédération française des Écoles de NAturopathie - <a href="http://lafena.fr/" target="_blank" rel="noopener noreferrer">FENA</a>.</p>
                <div className="infos-maintenance">
                  <h3 className="is-size-5">Consultation sur rendez-vous uniquement :</h3>
                  <p><FontAwesomeIcon icon="map-marked-alt" /><span className="intitule">Adresse :</span> 67 Boulevard de l'Embouchure, 31200 Toulouse.</p>
                  <p><FontAwesomeIcon icon={['far', 'envelope']} /><span className="intitule">Email :</span> <a className="mail" href="mailto:cynthia@cmanaturopathe.com" rel="noopener noreferrer">cynthia@cmanaturopathe.com</a></p>
                  <p><FontAwesomeIcon icon="phone" /><span className="intitule">Téléphone :</span> 06 81 59 35 04</p>
                </div>
                <div className="facebook-maintenance"> 
                  <p>Retrouvez toutes mes actualités sur ma page <a href="https://www.facebook.com/cmanaturopathe/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={['fab', 'facebook-square']} /> Facebook</a>.</p>
                </div>
                <p>Au plaisir de vous accueillir.<br/>A bientôt, Cynthia.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Landing
